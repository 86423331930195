export const environment = {
  production: true,
  region: 'ap-southeast-2',
  endpoint: 'https://staging.api.reali.au',
  wsEndPoint: 'wss://staging.api.reali.au',
  logLevel: 'debug',
  appName: 'REALI',
  buildId: process.env.NG_APP_BUILD_ID || 'local',
  defaultCollectionLimit: 10,
  enableSentry: true,
  defaultTestEmailAddress: "",
version: "staging_838",
  baseDomain: 'staging.reali.au',
  readApiToken: "39cafcf2862b2e6627657b726f76d0371fd0e690",
  analyticsTrackingCode: 'G-M4GDRRC77E',
  defaultSsoPool: '',
  defaultSsoDomain: ''
};
